import {
  Button,
  ButtonProps,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  useDisclosure
} from '@chakra-ui/react'
import { useRef } from 'react'

export type UseConfirmDialogProps = {
  header?: JSX.Element | string
  body?: JSX.Element | string
  cancelButton?: ButtonProps
  okButton: ButtonProps
} & Omit<ModalProps, 'isOpen' | 'children' | 'onClose'>

type UseModal = [
  (props: UseConfirmDialogProps) => JSX.Element,
  {
    isOpen: boolean
    onOpen: () => void
    onClose: () => void
    onToggle: () => void
    containerRef: React.RefObject<HTMLDivElement>
  }
]

export const useConfirmDialog = (): UseModal => {
  const methods = useDisclosure()
  const containerRef = useRef<HTMLDivElement>(null)
  const { isOpen, onClose } = methods
  const renderModal = ({
    body,
    header,
    okButton,
    cancelButton,
    ...modalProps
  }: UseConfirmDialogProps) => (
    <Modal
      variant="confirm"
      motionPreset="slideInBottom"
      scrollBehavior="inside"
      {...modalProps}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent ref={containerRef}>
        {header && <ModalHeader>{header}</ModalHeader>}
        {body && (
          <ModalBody
            bg="white.100"
            fontSize="13px"
            fontWeight={400}
            lineHeight="20.8px"
            color="gray.800"
          >
            {body}
          </ModalBody>
        )}
        <ModalFooter>
          <Flex w="100%" gap="8px">
            {cancelButton && (
              <Button
                variant="outline"
                h="48px"
                w="50%"
                fontSize="14px"
                {...cancelButton}
                onClick={async (e) => {
                  await cancelButton.onClick?.(e)
                  onClose()
                }}
              >
                {cancelButton.children || 'キャンセル'}
              </Button>
            )}
            <Button
              h="48px"
              w={cancelButton ? '50%' : '100%'}
              fontSize="14px"
              {...okButton}
              onClick={async (e) => {
                try {
                  await okButton.onClick?.(e)
                  onClose()
                } catch (error) {
                  console.log(error)
                }
              }}
            >
              {okButton.children || 'OK'}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )

  return [renderModal, { ...methods, containerRef }]
}
