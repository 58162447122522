import { Box, Flex, IconButton, Text } from '@chakra-ui/react'
import NextLink from 'next/link'
import { response_user_userType } from 'src/api'
import { ClosingProgressContextProvider } from 'src/context/ClosingProgressContext'
import { useUserIsTestUser } from 'src/hooks/user/useUserIsTestUser'
import { useUserType } from 'src/hooks/useUserType'
import { PreviousArrowIcon } from '../Icons/PreviousArrowIcon'
import { YearSwitcher } from '../YearSwitcher'
import { CarriedForwardJobProgressBar } from './CarriedForwardJobProgressBar'
interface CommonPageHeaderProps {
  leftIcon?: JSX.Element
  title: string
  hasYearSwitcher?: boolean
  /**
   * タイトルの右側に表示するアイコン
   */
  rightIcon?: JSX.Element
  previousPath?: string
  previousAction?: () => void
}

const { NORMAL } = response_user_userType.user_type

export const CommonPageHeader = ({
  leftIcon,
  title,
  hasYearSwitcher,
  rightIcon,
  previousPath,
  previousAction
}: CommonPageHeaderProps) => {
  const { userType } = useUserType()
  const { isTestUser } = useUserIsTestUser()

  return (
    <ClosingProgressContextProvider>
      <Flex
        align="center"
        backgroundColor="white"
        py={['10px', '12px']}
        h={['44px', '63px']}
        px={['20.5px', '32px']}
        borderBottom={[0, '1px solid']}
        borderBottomColor={['none', 'gray.200']}
        boxShadow={['0px 2px 4px 0px #00000014', 'none']}
        zIndex={10}
      >
        <Flex
          alignItems={'center'}
          justify="space-between"
          w="100%"
          gap={['6px', '16px']}
        >
          {previousPath ? (
            <NextLink passHref href={previousPath || '/'}>
              <IconButton
                aria-label="戻る"
                fontSize="24px"
                variant="ghost"
                p={0}
                minW="24px"
                icon={<PreviousArrowIcon />}
                bgColor="transparent"
              />
            </NextLink>
          ) : previousAction ? (
            <IconButton
              aria-label="戻る"
              fontSize="24px"
              variant="ghost"
              p={0}
              minW="24px"
              icon={<PreviousArrowIcon />}
              bgColor="transparent"
              onClick={previousAction}
            />
          ) : null}
          {leftIcon}
          <Flex justify={['center', 'flex-start']} flex={1} align="center">
            <Text
              fontWeight={[700, 'black']}
              fontSize={['16px', '24px']}
              lineHeight={[1.5, '40px']}
              ml={leftIcon ? '10px' : 0}
            >
              {title}
            </Text>
            {/* ULTRAユーザーには年度選択はさせない */}
            {hasYearSwitcher &&
              (isTestUser === true || userType === NORMAL) && <YearSwitcher />}
          </Flex>
          {rightIcon ?? (
            // SP版のときにページタイトルを真ん中に寄せるためのおまじない
            <Box display={['block', 'none']} w="24px" />
          )}
        </Flex>
      </Flex>
      <CarriedForwardJobProgressBar />
    </ClosingProgressContextProvider>
  )
}
