import useSWRImmutable from 'swr/immutable'
import { useNewApiClient } from '../useNewApiClient'

export const useUserIsTestUser = () => {
  const { apiClient } = useNewApiClient()
  const { data: isTestUser } = useSWRImmutable(
    '/user/is_test_user',
    async () => {
      const res = await apiClient.user.getUserIsTestUser()
      return res.is_test_user
    }
  )

  return {
    isTestUser
  }
}
