import { QuestionIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  IconProps,
  PlacementWithLogical,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure
} from '@chakra-ui/react'

export const QuestionTooltipWrapper: React.FC<{
  label: string | JSX.Element
  placement: PlacementWithLogical
}> = ({ children, placement, label }) => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <Popover
      placement={placement || 'top-start'}
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      closeDelay={200}
      trigger="hover"
    >
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        w="376px"
        borderRadius="4px"
        border="none"
        _focus={{ outline: 'none' }}
        bgColor="white.100"
        p="0"
        left="-8px"
        boxShadow="0px 4px 20px 0px rgba(0, 0, 0, 0.1)"
      >
        <PopoverBody h="100%" w="100%" display="flex" p="0" border="none">
          <Center
            bgColor="purple.900"
            w="40px"
            borderLeftRadius="4px"
            position="relative"
          >
            <Box
              bgColor="white.100"
              w="20px"
              h="20px"
              borderRadius="full"
              position="absolute"
              zIndex={1}
            />
            <QuestionIcon
              fontSize="16.67px"
              color="purple.900"
              borderRadius="full"
              w="24px"
              h="24px"
              zIndex={2}
            />
          </Center>
          <Box
            color="purple.900"
            fontWeight={500}
            fontSize="13px"
            lineHeight={1.5}
            py="10px"
            px="16px"
          >
            {label}
          </Box>
        </PopoverBody>
        <PopoverArrow bg="purple.900" borderRadius="1px" />
      </PopoverContent>
    </Popover>
  )
}

export const QuestionTooltip: React.VFC<
  { label: string | JSX.Element } & IconProps
> = ({ label, ...props }) => {
  return (
    <QuestionTooltipWrapper label={label} placement="top-start">
      <QuestionIcon fontSize="16.67px" color="purple.900" {...props} />
    </QuestionTooltipWrapper>
  )
}
