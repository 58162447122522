import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Box, chakra, Link, Select, Text, useBoolean } from '@chakra-ui/react'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useClosingProgressContext } from 'src/context/ClosingProgressContext'
import { useNewApiClient } from 'src/hooks/useNewApiClient'
import { useProcessingYear } from 'src/hooks/useProcessingYear'
import { useConfirmDialog } from './Modals/ConfirmDialog'
import { QuestionTooltip } from './QuestionToolTip'

export const YearSwitcher = () => {
  const [renderNormalDialog, { onOpen: onOpenNormalDialog }] =
    useConfirmDialog()
  const [renderDiffDialog, { onOpen: onOpenDiffDialog }] = useConfirmDialog()
  const [isLoading, { on, off }] = useBoolean()
  const { restart } = useClosingProgressContext()
  const [willChangeYear, setWillChangeYear] = useState<number | undefined>()
  const { processing_year, patchProcessingYear } = useProcessingYear()
  const { apiClient } = useNewApiClient()

  return (
    <>
      {processing_year ? (
        <Select
          height={['32px', '40px']}
          marginLeft={'1em'}
          fontWeight={'bold'}
          width={'auto'}
          value={processing_year.processing_year}
          onChange={async (e) => {
            if (e) {
              setWillChangeYear(Number(e.target.value))
              if (Number(e.target.value) > processing_year.processing_year) {
                const { exist } =
                  await apiClient.revisedHistoricalInformation.getRevisedHistoricalInformationsExist()

                if (exist) {
                  onOpenDiffDialog()
                } else {
                  onOpenNormalDialog()
                }
              } else {
                onOpenNormalDialog()
              }
            }
          }}
          borderColor={'gray.200'}
        >
          <option value="2021">{`2021年${!isMobile ? '1~12月' : ''}分`}</option>
          <option value="2022">{`2022年${!isMobile ? '1~12月' : ''}分`}</option>
          <option value="2023">{`2023年${!isMobile ? '1~12月' : ''}分`}</option>
          <option value="2024">{`2024年${!isMobile ? '1~12月' : ''}分`}</option>
          <option value="2025">{`2025年${!isMobile ? '1~12月' : ''}分`}</option>
        </Select>
      ) : null}

      {renderNormalDialog({
        header: `${willChangeYear}年度の情報に切り替えますか？`,
        body: <Text>切り替えてもよろしいですか？</Text>,
        okButton: {
          onClick: async () => {
            if (willChangeYear) {
              await patchProcessingYear(willChangeYear)
            }
          },
          children: '切り替える'
        },
        cancelButton: {}
      })}
      {renderDiffDialog({
        header: (
          <>
            {`${processing_year?.processing_year}年度で変更した情報があります。`}
            <br />
            {`変更内容を保存して、${willChangeYear}年度に切り替えますか？`}
          </>
        ),
        body: (
          <Box>
            <Text fontSize="13px" lineHeight="20.8px">
              このまま元の会計期間に戻ると
              <chakra.span
                color="primary.alert"
                mx="4px"
                display="inline-flex"
                alignItems="center"
              >
                『修正申告』
                <QuestionTooltip
                  color="primary.alert"
                  label={
                    <Box>
                      <Box fontSize="13px" lineHeight="19.5px">
                        申告書の再提出を行う税務上の手続きを指します。
                        <br />
                        以下の書類提出が必要です。
                        <br />
                        ① 修正申告書
                        <br />
                        ② 確定申告書（申告書B第一表）
                        <br />
                      </Box>
                      <Link
                        display="block"
                        color="primary.black"
                        mt="12px"
                        textDecor="underline"
                        fontWeight={500}
                        fontSize="12px"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.nta.go.jp/taxes/shiraberu/taxanswer/shotoku/2026.htm"
                      >
                        詳しくはこちら（国税庁）
                        <ExternalLinkIcon
                          stroke="primary.black"
                          fontSize="14px"
                        />
                      </Link>
                    </Box>
                  }
                />
              </chakra.span>
              という、
            </Text>
            <Text mt="2px">
              「申告書の再提出」のようなタスクが必要になる可能性があります。
            </Text>
          </Box>
        ),
        okButton: {
          children: '変更内容を保存して戻る',
          isLoading,
          onClick: async () => {
            on()
            await apiClient.closing.patchClosing()
            if (willChangeYear) {
              await patchProcessingYear(willChangeYear)
            }
            off()
            restart()
          }
        },
        cancelButton: {}
      })}
    </>
  )
}
