import { useBoolean } from '@chakra-ui/react'
import { createContext, useContext, useEffect, useRef } from 'react'
import { useNewApiClient } from 'src/hooks/useNewApiClient'
import useSWR from 'swr'

type ClosingProgressContextType = {
  isProcessing: boolean | undefined
  showProgress: boolean
  restart: VoidFunction
  offShowProgress: VoidFunction
}

const ClosingProgressContext = createContext<ClosingProgressContextType>(
  {} as ClosingProgressContextType
)

// 次年度繰越処理の継続中かどうかのUIを管理するContext
export const ClosingProgressContextProvider: React.FC = ({ children }) => {
  const { apiClient } = useNewApiClient()
  // UIとしてプログレスバーを出しているか
  const [showProgress, { on, off }] = useBoolean()
  // GET /carried_forward_jobs/activeの初回ロードを済ませているか
  const isMountRef = useRef(false)
  // GET /carried_forward_jobs/activeに対しポーリングをすべきか
  const shouldRefreshRef = useRef(false)
  const fetchCarriedForwardJobStart = async () => {
    const { is_processing } =
      await apiClient.carriedForwardJob.getCarriedForwardJobsActive()
    return is_processing
  }
  const { data: isProcessing } = useSWR(
    shouldRefreshRef.current ? `/carried_forward_jobs/active` : null,
    async () => {
      return await fetchCarriedForwardJobStart()
    },
    { refreshInterval: 3000 }
  )

  const check = async () => {
    if (isMountRef.current === false) {
      const shouldStartLoading = await fetchCarriedForwardJobStart()
      isMountRef.current = true
      shouldRefreshRef.current = shouldStartLoading
      if (shouldStartLoading) {
        on()
      }
    } else {
      shouldRefreshRef.current = isProcessing === true
    }
  }

  useEffect(() => {
    check()
  }, [isProcessing])

  // 外部からポーリングを再起動するためのメソッド
  const restart = () => {
    isMountRef.current = false
    shouldRefreshRef.current = false
    off()
    check()
  }
  return (
    <ClosingProgressContext.Provider
      value={{
        isProcessing,
        showProgress,
        restart,
        offShowProgress: off
      }}
    >
      {children}
    </ClosingProgressContext.Provider>
  )
}

export const useClosingProgressContext = () => {
  const context = useContext(ClosingProgressContext)
  if (!context)
    throw new Error(
      'Tax Task Page Context must be used inside GlobalContextProvider'
    )
  return context
}
