import { Button, Flex, Text } from '@chakra-ui/react'
import { useClosingProgressContext } from 'src/context/ClosingProgressContext'

export const CarriedForwardJobProgressBar: React.FC = () => {
  const { offShowProgress, showProgress, isProcessing } =
    useClosingProgressContext()
  return showProgress ? (
    <Flex
      bgColor="purple.900"
      color="white.100"
      gap="20px"
      align="center"
      h="48px"
      px="20px"
    >
      <Flex gap="10px" align="center">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 22C14.7614 22 17.2614 20.8807 19.0711 19.0711C20.8807 17.2614 22 14.7614 22 12C22 9.2386 20.8807 6.7386 19.0711 4.92893C17.2614 3.11929 14.7614 2 12 2C9.2386 2 6.7386 3.11929 4.92893 4.92893C3.11929 6.7386 2 9.2386 2 12C2 14.7614 3.11929 17.2614 4.92893 19.0711C6.7386 20.8807 9.2386 22 12 22Z"
            fill="#EAEAF8"
          />
          <path
            d="M12 15.8867V11.8867"
            stroke="#555978"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 7.88672H12.01"
            stroke="#555978"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <Text fontSize="14px" lineHeight={1} fontWeight={500}>
          {isProcessing
            ? '変更内容の更新をしています。しばらくしてからもう一度アクセスしてください。'
            : '変更内容の更新が完了しました。'}
        </Text>
      </Flex>
      {!isProcessing && (
        <Button
          variant="outline"
          border="0px"
          minH="30px"
          h="30px"
          w="63px"
          fontSize="13px"
          lineHeight={1}
          onClick={offShowProgress}
        >
          閉じる
        </Button>
      )}
    </Flex>
  ) : null
}
