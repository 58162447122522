import { createIcon } from '@chakra-ui/react'

export const PreviousArrowIcon = createIcon({
  displayName: 'PreviousArrowIcon',
  viewBox: '0 0 20 20',

  path: (
    <>
      <path d="M20 0H0V20H20V0Z" fill="white" fillOpacity="0.01" />
      <path
        d="M2.41602 10H17.416"
        stroke="#2D2D2D"
        strokeWidth="1.66667"
        strokeLinecap="square"
      />
      <path
        d="M7.416 15L2.41602 10L7.416 5"
        stroke="#2D2D2D"
        strokeWidth="1.66667"
        strokeLinecap="square"
        fill="none"
      />
    </>
  )
})
